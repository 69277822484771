<template>
  <div v-if="!isEmpty(project)" class="project-detail__header row card">
    <!-- Main Photo -->
    <div class="header-image">
      <cool-light-box
        :items="[$getFileURL(project.mainPhoto[0])]"
        :index="projectMainPhotoIndex"
        @close="projectMainPhotoIndex = null"
        :slideshow="false"
      >
      </cool-light-box>
      <img
        class="main-photo cursor-pointer mb-2 md-m-2 md-mb-0 md-mr-2"
        :src="$getFileURL(project.mainPhoto[0])"
        :alt="project.name"
        @error="$setAltPhoto"
        @click="projectMainPhotoIndex = 0"
      />
    </div>

    <div class="row header-content">
      <div class="col-12 md-col-10 mb-1 md-my-1">
        <!-- Project Name & Type -->
        <div class="d-flex align-items-center">
          <div class="mr-1">
            <chip outline>{{ project.referenceCode }}</chip>
          </div>
          <h2 class="project-name d-inline-block mr-2">
            {{ project.name }}
          </h2>
          <span class="project-type d-inline-block">{{
            project.projectType
          }}</span>
        </div>
      </div>
      <!-- Status -->
      <div class="col-12 md-col-2 mb-1 md-my-1 align-items-center">
        <div class="d-flex md-justify-content-end">
          <project-status
            class="status self-align-end"
            :status="project.status"
          ></project-status>
        </div>
      </div>
      <div class="col-12">
        <!-- Developer -->
        <p class="sub-context">
          <i class="fas fa-hard-hat mr-1"></i>
          {{ project.developer.name }}
        </p>
        <!-- Address -->
        <p class="sub-context">
          <i class="fas fa-map-marker-alt mr-1"></i>
          <span v-if="project.building"
            >{{ $toTitleCase(project.building.name) }},</span
          >
          {{ $toTitleCase(project.area.name) }},
          {{ $toTitleCase(project.city.name) }},
          {{ $toTitleCase(project.state.name) }}
        </p>
      </div>
      <!-- Tags -->
      <div v-if="project.hashtag !== ''" class="col-12 mt-2 mb-3">
        <chip
          v-for="(tag, index) in project.hashtag.split(',')"
          :key="index"
          class="mr-1"
          >{{ tag }}</chip
        >
      </div>
      <!-- Pricing -->
      <div
        v-if="project.salePriceMin && project.salePriceMax"
        class="pricing col-12 mb-2"
      >
        <p class="salePrice">
          <i class="fas fa-dollar-sign mr-1"></i>
          RM{{ $numberWithCommas(parseInt(project.salePriceMin)) }} - RM{{
            $numberWithCommas(parseInt(project.salePriceMax))
          }}
        </p>
        <p class="salePricePerUnit">
          RM{{ $numberWithCommas(project.salePricePerUnitMin) }}/{{
            $numberWithCommas(project.areaUnitType)
          }}
          - RM{{ project.salePricePerUnitMax }}/{{ project.areaUnitType }}
        </p>
      </div>
      <div v-if="!$isStringEmpty(project.completionYear)" class="col-6 mb-2">
        <p class="font-bold mb-1">Completion Year</p>
        <chip>{{ project.completionYear }}</chip>
      </div>
      <!-- Introduction -->
      <div v-if="!isHTMLStringEmpty(project.introduction)" class="col-12">
        <p class="font-bold mb-1">Introduction</p>
        <show-more :content="project.introduction"></show-more>
      </div>
    </div>
  </div>
</template>

<script>
import { isHTMLStringEmpty } from "@/utils/string";
import isEmpty from "lodash/isEmpty";

export default {
  components: {
    ProjectStatus: () => import("@/modules/Project/components/ProjectStatus"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    ShowMore: () => import("@/components/GlobalComponents/ShowMore")
  },
  mixins: [],
  props: {
    project: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    editProjectRouteName: {
      type: String,
      default: "ManageProjectsEdit"
    }
  },
  data: function () {
    return {
      // Lightbox and images
      projectMainPhotoIndex: null,

      isHTMLStringEmpty: isHTMLStringEmpty,
      isEmpty: isEmpty
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-detail__header {
  @mixin outerSpacing {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;

    @media #{$breakpoint-down-sm} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  margin-bottom: 15px;
  flex-wrap: nowrap;

  @media #{$breakpoint-down-sm} {
    flex-wrap: wrap !important;
  }

  .header-image {
    @media #{$breakpoint-down-sm} {
      width: 100%;
    }
    img.main-photo {
      @include image(cover);
      width: 200px;
      height: 200px;
      border-radius: 5px;
      box-shadow: 0px 2px 10px #00000030;
      @media #{$breakpoint-down-sm} {
        height: auto;
        width: 100%;
        border-radius: 0px;
      }
      &:hover {
        box-shadow: 0px 4px 18px #00000040;
      }
    }
  }

  .header-content {
    flex-grow: 1;
    align-content: flex-start;
    @include outerSpacing();
  }

  h2 {
    @media #{$breakpoint-down-sm} {
      font-size: 18px;
    }
  }
  h5 {
    @media #{$breakpoint-down-sm} {
      font-size: 14px;
    }
  }
  .project-type {
    background: $color-secondary;
    color: white;
    border-radius: 5px;
    padding: 6px 12px;
  }
  .sub-context {
    color: #656565;
    font-size: 16px;
    @media #{$breakpoint-down-sm} {
      font-size: 14px;
    }
  }
  .status {
    width: fit-content;
    padding: 6px 12px;
  }

  .pricing {
    .salePrice {
      font-size: 16px;
    }
    .salePricePerUnit {
      font-size: 12px;
      color: #5b5b5b;
    }
  }
}
</style>
